.contact-banner-section {
  background-image: url("../../assets/Images/ContactImg/contact-banner.gif");
  position: relative;
  height: 50vh;
  color: white;
  background-position:right;
  background-repeat: no-repeat;
  background-size: cover;
}
.contact-banner-section::before {
  background: linear-gradient(90deg, #05bfdb, transparent);
  content: "";
  display: block;
  height: 50vh;
  position: absolute;
  width: 100%;
  transform: translateX(-480px);
}
.contact-title{
z-index: 10;
position: relative;   
}
