/* ************footer************** */

.footer-part {
  background-image: url(../common/imgs/footer-banner-image.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.footer-part:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: -1;
  opacity: 0.9;
}

ul.footer-contact li a {
  text-decoration: none;
  color: white;
  font-size: 500;
  font-weight: 600;
}
.footer-icon {
  padding-right: 15px;
  color: #14b1bb;
}
.social-icon {
  color: #fff;
}
.social-link a {
  display: inline-block;
  background-color: #14b1bb;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  margin: 5px;
}
.footer-text {
  color: #14b1bb;
  font-size: 20px;
  font-weight:500;
}

a .footer-text {
  text-decoration: none !important;
}
