.about-banner-section {
  background-image: url("../../assets/Images/AboutImage/AboutBannerImg1.png");
  background-attachment: fixed;
  height: 100vh;
  background-position:center;
  background-size: cover;
}

.about-cards-section:hover{
  background-color: #C8F1F7;
  cursor: pointer;
}

.about-cards-section{
  transition:all 0.6s ease-in-out ;
}



