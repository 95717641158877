/* .home-service-card :hover {
  animation: zoomOut 1s ease-in-out;
  cursor: pointer;
} */
.animate-slowly {
  animation-duration: 4s; 
}

.home-service-card {
  transition: all ease 0.8s;
  cursor: pointer;
}

.home-service-card:hover {
  transform: scale(1.05);
}
.home-features-section :hover {
  
  background-color: #0f7a81;
  cursor: pointer;
}

/* our services */

.home-services-icon {
  color: #14b1bb;
}

.home-services-heading {
  color: #262566;
}

.home-services-paragraph {
  color: #5f5f5f;
}

.home-services-slide {
  background-color: transparent;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.home-services-slide::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #14b1bb;
  z-index: -1;
  transition: left 0.5s ease-in-out;
  opacity: 1;
}

.home-services-slide:hover::before {
  left: 0;
}

.home-services-slide:hover .home-services-heading,
.home-services-slide:hover .home-services-paragraph,
.home-services-slide:hover .home-services-icon {
  color: white;
}

.home-banner-section {
  background-image: url("../../assets/Images/Homepageimages/home-banner-image1.png");
  background-attachment: fixed;
  height: 100vh;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
}
.home-hover-button1:hover {
  background: linear-gradient(45deg, #05bfdb, #0a4d68) !important;
  transform: scale(1.05);
}

.home-hover-button2:hover {
  background: linear-gradient(45deg, #05bfdb, #264a5a) !important;
  transform: scale(1.05);
  border: none;
}

.wrapper {
  display: inline-flex;
  list-style: none;
}

.wrapper .icon {
  position: relative;
  background:linear-gradient(97.41deg, #0A4D68 8.68%, #05BFDB 140.45%);
  border-radius: 50%;
  padding: 15px;
  margin: 40px 10px;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  color:white;
}

.wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
  background: #1877f2;
  color: #ffffff;
}

.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
  background: #e4405f;
  color: #ffffff;
}

.wrapper .linkedin:hover .tooltip,
.wrapper .linkedin:hover .tooltip::before {
  background:#0072b1;
  color: #ffffff;
}

.blog-effect-box{
  position: relative;
  overflow: hidden;
}

.blog-effect-box img {
  width: 100%;
  transition: 0.5s ease;
}
.blog-effect-box:hover img {
  transform: scale(1.1);
}
.blog-effect-layer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 4rem;
  opacity: 0;
  transition: 0.5s ease;
  background-color :rgba(0, 0, 0, 0.4);
  color:white;
  cursor:pointer;
  
}
.blog-effect-box:hover .blog-effect-layer {
  opacity: 1;
}

/* our-portfolio */
.recent-work-card{
  position: relative;
  overflow: hidden;
}
 
.recent-work-card img {
  width: 100%;
  transition: 0.5s ease;
}
.recent-work-card:hover img {
  transform: scale(1.1);
}
.recent-effect-layer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 4rem;
  opacity: 0;
  transition: 0.5s ease;
  background-color :rgba(0, 0, 0, 0.4);
  color:white;
  cursor:pointer;
 
}
.recent-work-card:hover .recent-effect-layer {
  opacity: 2;
}
