.services-technologies{-webkit-column-count:7;-moz-column-count:7;column-count:7;-webkit-column-gap:10px;-moz-column-gap:10px;column-gap:10px;margin:5px;padding:0;}
.services-technologies .services-technologies-item{display:inline-block;width:100%;padding:20px;background:#fff;margin:0 0 5px;height:100%;width:100%;-webkit-transition:1s ease all;border-radius:6px;overflow:hidden;box-shadow:0 0 10px #00000019;}
.services-technologies .services-technologies-item .services-technologies-image{width:100%;max-height:50px;height:100%;object-fit:contain;}
.services-technologies .services-technologies-item .services-technologies-title{text-align:center;font-size:16px;margin-top:10px;}
@media only screen and (max-width: 420px){
.services-technologies{-moz-column-count:2;-webkit-column-count:2;column-count:2;}
}
@media only screen and (min-width: 421px) and (max-width: 768px){
.services-technologies{-moz-column-count:3;-webkit-column-count:3;column-count:3;}
}
@media only screen and (min-width: 769px) and (max-width: 1000px){
.services-technologies{-moz-column-count:5;-webkit-column-count:5;column-count:5;}
}
