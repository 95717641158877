.webtalent-box {
  position: relative;
  overflow: hidden;
}

.webtalent-box img {
  width: 100%;
  transition: 0.5s ease;
}
.webtalent-box:hover img {
  transform: scale(1.1);
}
.webtalent-layer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 4rem;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.5);
  color:white
  
}
.webtalent-box:hover .webtalent-layer {
  opacity: 1;
}

.webtalent-card:hover {
 animation: pulse 1s ease-in-out;
 cursor: pointer;
}
/* .roadmap-ordered-item{
  list-style-type: decimal;
} */
