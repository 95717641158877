.talent-banner-section{
    background-image: url("../../assets/Images/talentgravityimg/talentpathbanner.jpg");
    /* background-attachment: fixed; */
    height: 110vh;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
  }

@media only screen and (max-width: 576px) {
  .talent-banner-section {
    height: 33vh;
    width: 100%;
    background-position: center;
  }
}
